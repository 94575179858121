import React from 'react'
import Link from '../components/Link'
import { Four, One, OneThird, Row, Three, Two } from '../components/Grid'
import SEO from '../components/SEO'
import { H1, H2, H4, SmallText } from '../components/Style'
import Layout from '../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/privacy",
      "name": "Privacy Policy"
    }
  }]
}`

export default () => {
  return (
    <Layout>
      <SEO
        path="/privacy"
        title="500Tech' Privacy Policy"
        description=""
        ld={breadcrumbs}
      />

      <Row>
        <Four>
          <H1>Privacy policy</H1>
        </Four>
      </Row>

      <Row>
        <Three>
          <p>
            This privacy policy discloses how 500Tech ("we", "us") gathers,
            uses, and protects your personal data and applies to personal data
            collected through this website or, as described below, by other
            means during the course of its business.
          </p>
          <p>Last updated on September 5th, 2018</p>
        </Three>
        <One>
          <SmallText>
            <H4>Inquiries and support</H4>
            If you have any questions about this privacy policy or the
            information we hold about you, you can email us at{' '}
            <Link tp="mailto:privacy@500tech.com">privacy@500tech.com</Link>, or
            use any contact information at the bottom of the page.
          </SmallText>
        </One>
      </Row>

      <Row>
        <Three>
          <H2>Collected information</H2>
          We collect certain technical and personal information through our
          website, landing pages hosted by third-party providers, through
          cookies, or sign-up sheets circulated at events.
        </Three>
      </Row>

      <Row>
        <One>
          <H4>Analytics</H4>
          <SmallText>
            <p>
              When you visit our website, we collect certain technical
              information about your visit, such as your IP address, login data,
              browser type and version, time zone, location, type of device,
              operating system, screen size, and visited pages.
            </p>
          </SmallText>
        </One>
        <One>
          <H4>Sales and marketing</H4>
          <SmallText>
            <p>
              When you reach out to us to make an inquiry, interact with any of
              our open-source projects, or contact us about our work or events
              we collect certain personal information during our interactions
              such as name, contact details, and current employer and role.
            </p>
          </SmallText>
        </One>
        <One>
          <H4>Employment</H4>
          <SmallText>
            <p>
              When you submit an application to our open position or contact us
              about potential work we collect certain personal information such
              as name, contact details, employer and job title, and previous
              work experience.
            </p>
          </SmallText>
        </One>
        <One>
          <H4>Events</H4>
          <SmallText>
            <p>
              When you register to or participate in our events or events where
              we participate, we may collect or receive from third parties
              certain personal information such as name, contact details,
              employer and job title, and event attendance status.
            </p>
          </SmallText>
        </One>
      </Row>

      <Row>
        <Three>
          <p>
            In any case we do not collect any special categories of personal
            data such as your race or ethnicity, religious or philosophical
            beliefs, sex life, sexual orientation, political opinions, trade
            union membership, information about your health and genetic and
            biometric data. Nor do we collect any information about criminal
            convictions and offences.
          </p>
        </Three>
      </Row>

      <Row>
        <Two>
          <H2>Sharing your data</H2>
          <p>
            We may share your personal data between our group companies in
            Israel and the US, for the purposes of performing our contractual
            obligations to our clients or furthering our legitimate business
            interests.
          </p>

          <p>
            We never pass your personal data to anyone else, except for any
            successors in title to our business and associated group companies
            or to suppliers that may process data on our behalf as part of
            providing a service to you. We may also disclose your personal
            information if we are required to do so by law.
          </p>

          <p>
            We don't sell your personal data, but we cannot be held responsible
            for the actions of any third party sites from which you may have
            linked or been directed to our website.
          </p>
        </Two>
        <Two>
          <H2>Storing your data</H2>
          <p>
            We retain your personal information for as long as necessary to
            fulfil the purposes we collected it for, including for the purposes
            of satisfying any legal, accounting, or reporting requirements.
          </p>

          <p>
            To determine the appropriate retention period for personal data, we
            consider the amount, nature, and sensitivity of the personal data,
            the potential risk of harm from unauthorized use or disclosure of
            your personal data, the purposes for which we process your personal
            data and whether we can achieve those purposes through other means,
            and the applicable legal requirements.
          </p>

          <p>
            Once the personal information is no longer required, we will
            securely destroy your personal information in accordance with
            applicable laws and regulations.
          </p>
        </Two>
      </Row>

      <Row>
        <Three>
          <H2>Cookies</H2>
          <p>
            Cookies are small files that are placed on your computer or mobile
            device by a website or an application to store and retrieve
            information about your visits or distinguish you from other users.
            Learn more about cookies:{' '}
            <Link to="http://allaboutcookies.org">www.allaboutcookies.org</Link>
          </p>

          <p>
            We and our third-party providers use cookies to analyze trends,
            administer the website, track users movements around the website,
            and gather demographic information.
          </p>

          <p>
            You can control and refuse the use of cookies in your browser.
            Cookies can be deleted. If you reject cookies, you may still use our
            website, but your ability to use some features or areas of our
            website may be limited.
          </p>
        </Three>
      </Row>

      <Row>
        <OneThird>
          <H4>Strictly necessary cookies</H4>
          <SmallText>
            Some cookies are required for the operation of our website, for
            example to authorize access to internal parts of the website.
            Without them services such as registrations to certain events cannot be
            provided.
          </SmallText>
        </OneThird>
        <OneThird>
          <H4>Analytics and performance cookies</H4>
          <SmallText>
            We use web analytics services to understand how people use our
            website. Cookies recognize your browser or device, identify whether
            you have visited our website before, what you have previously viewed
            or clicked on, or how you found us. These cookies are anonymous, and
            only used for statistical purposes.
          </SmallText>
        </OneThird>
        <OneThird>
          <H4>Functional cookies</H4>
          <SmallText>
            We may use cookies to remember choices you make, provide enhanced
            personal features, and serve content based on your profile. Such
            cookies cannot track your browsing activity on other websites nor
            gather any personal information about you.
          </SmallText>
        </OneThird>
      </Row>

      <Row>
        <Three>
          <p>
            By referring to the general term “cookie”, we also assume other
            tracking technologies such as the browser’s local or session storage
            that might be used for same purposes.
          </p>
        </Three>
      </Row>

      <Row>
        <Three>
          <H2>Your rights</H2>
          <p>
            Under certain circumstances, you have the lawful right to request
            access, correct, erase, or transfer your information as well as
            object or restrict its processing. You can email, call, or write to
            us with proof of your identity and address and specify the the
            action to perform and the type of information.
          </p>

          <p>
            You may opt-out of automated email communications by clicking the
            unsubscribe link at the bottom of our emails.
          </p>

          <p>
            You may opt-out of Google’s analytics tracking from all websites by
            installing{' '}
            <Link to="https://tools.google.com/dlpage/gaoptout">
              <nobr>Google Opt-Out Browser Add-on</nobr>
            </Link>.
          </p>

          <p>
            You may prevent our websites to collect information through the use
            of cookies by disabling and/or deleting them in your browser
            settings. This may affect and/or disable some features of our
            websites.
          </p>
        </Three>
      </Row>

      <Row>
        <Three>
          <p>
            We may revise this policy and our use of cookies from time to time
            without prior notice. Changes to this policy will be effective upon
            posting on the Site. The latest version will be provided on the
            site. You may check this policy as necessary.
          </p>
        </Three>
      </Row>
    </Layout>
  )
}
